@import 'assets/stylesheets/commons';

$map-info-box-background-color: $primary-color;
$map-info-box-secondary-background-color: $secondary-color;

$map-info-box-title-color: $base-white-color;
$map-info-box-list-item-color: $base-white-color;

.infoBox,
.infoBoxSecondary {
  padding: 27px 30px;

  background: $map-info-box-background-color;

  @include media(small mobile) {
    margin-right: -25px;
  }

  &Title {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.7;

    letter-spacing: 1px;

    color: $map-info-box-title-color;
  }

  &Content {
    @include font-smoothing;

    font-size: 19px;
    line-height: 1.3;

    color: $map-info-box-title-color;
  }

  &Text {
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &List {
    margin: 0;
    padding: 0;

    list-style: none;

    &Item {
      display: block;
      font-size: 18px;
      font-weight: 400;

      position: relative;

      margin: 8px 0;
      padding-left: 28px;

      cursor: pointer;
      vertical-align: middle;

      color: $map-info-box-list-item-color;

      &:hover {
        text-decoration: underline;
      }

      // scss-lint:disable NestingDepth
      &::before {
        position: absolute;
        top: 5px;
        left: 0;

        display: inline-block;

        width: 18px;
        height: 12px;

        content: '';

        background: url('../../icons/dot-on.svg') no-repeat center center;
      }

      &::after {
        display: inline-block;

        width: 21px;
        height: 16px;

        margin-left: 8px;

        content: '';

        vertical-align: middle;

        background: url('../../icons/arrow-right.svg') no-repeat center right;
      }
      // scss-lint:enable NestingDepth
    }
  }
}

.infoBoxSecondary {
  background: $map-info-box-secondary-background-color;
}
