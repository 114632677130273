@import 'assets/stylesheets/commons';

$jumbotron-default-mask-background-color: $base-white-color;
$jumbotron-dimmed-mask-background-color: $base-gray-lighter-color;
$jumbotron-primary-background-color: $primary-color;
$jumbotron-primary-dark-background-color: $base-dark-purple;
$jumbotron-primary-darker-background-color: $base-darker-purple;
$jumbotron-secondary-background-color: $secondary-color;
$jumbotron-ternary-background-color: $ternary-color;

.jumbotron {
  position: relative;

  background: {
    repeat: no-repeat;
    position: center center;
    size: cover;
  }

  &Primary {
    background-color: $jumbotron-primary-background-color;
  }

  &PrimaryDark {
    background-color: $jumbotron-primary-dark-background-color;
  }

  &PrimaryDarker {
    background-color: $jumbotron-primary-darker-background-color;
  }

  &Secondary {
    background-color: $jumbotron-secondary-background-color;
  }

  &Ternary {
    background-color: $jumbotron-ternary-background-color;
  }

  &Dark {
		background-color: $jumbotron-primary-background-color;
    color: $base-greyish-brown;
  }

  &Mask {
    position: absolute;
    z-index: $z-1;
    bottom: -1px; // HACK Hide bottom line on handheld devices

    polygon {
      fill: $jumbotron-default-mask-background-color;
    }

    &Dimmed polygon {
      fill: $jumbotron-dimmed-mask-background-color;
    }
  }

  &Content {
    @include brokenships-container($container-max-width);

    position: relative;

    padding-bottom: 20px;
  }
}
