$font-family-sans-serif: var(--font-family-sans-serif);

$font-size-base: 16px;
$font-size-large: 20px;
$font-size-small: 14px;

$font-size-h1: 56px;
$font-size-h2: 44px;
$font-size-h3: 36px;
$font-size-h4: 20px;
$font-size-h5: $font-size-base;
$font-size-h6: 14px;
