@import 'assets/stylesheets/commons';

$pin-border-color: $base-pinkish-grey-color;
$pin-text-color: $base-warm-gray-color;

.addPost {
  position: relative;

  display: block;
  overflow: hidden;

  width: span(3);
  height: 300px;
  padding: {
    top: 110px;
  }

  cursor: pointer;
  text-decoration: none;

  border-top: 2px dashed $pin-border-color;

  @include media(desktop large) {
    &:hover &Icon {
      transform: scale(1.2);
    }
  }

  @include media(tablet) {
    width: span(6);
  }

  @include media(small mobile) {
    display: flex;

    flex-direction: column;

    width: span(12);
    height: 240px;
    padding-top: 0;

    align-items: center;
    justify-content: center;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    content: '';
    transform: skewY(-2deg);
    transform-origin: 0 50%;

    border: {
      width: 2px;

      style: dashed;

      top-color: transparent;
      right-color: $pin-border-color;
      bottom-color: $pin-border-color;
      left-color: $pin-border-color;
    }
    box-shadow: none;
  }

  &Icon {
    display: block;

    width: 42px;
    margin: 0 auto;

    @include media(desktop large) {
      transition: transform 0.5s cubic-bezier(0.7, -1, 0.3, 2);

      transform: scale(1);
    }
  }

  &Text {
    font-size: 19px;
    font-weight: 500;
    line-height: 22px;

    padding-top: 23px;

    text-align: center;

    color: $pin-text-color;
  }
}
