@import 'assets/stylesheets/commons';

$social-bar-more-overlay-color: rgba($base-black-color, 0.5);
$social-bar-more-menu-color: $secondary-color;
$social-bar-more-menu-text-color: $secondary-text-color;

.menu {
  @include stretch;

  &Backdrop {
    @include stretch($bottom: 50px);

    z-index: $z-3;

    background: $social-bar-more-overlay-color;

    &TopSkew {
      @include media(small mobile tablet) {
        transform: skewY(-2deg);

        transform-origin: 0 50%;
      }
    }
  }

  &Container {
    @include stretch($top: auto);

    z-index: $z-4;

    &::before {
      @include stretch($top: 15px);

      content: '';
      transform: skewY(-2deg);

      background: $social-bar-more-menu-color;
    }

    &NoSkew {
      &::before {
        transform: skewY(0);
      }
    }
  }

  &List {
    position: relative;

    margin-bottom: 52px;
    padding-top: 10px;

    background: $social-bar-more-menu-color;

    @include media(tablet desktop large) {
      margin-bottom: 42px;
    }
  }

  &Item {
    @include font-smoothing;

    font-size: 18px;
    font-weight: 600;
    line-height: 20px;

    display: block;

    padding: 9px 32px;

    cursor: pointer;
    user-select: none;
    text-align: left;
    text-decoration: none;

    color: $social-bar-more-menu-text-color;

    &:hover {
      text-decoration: underline;
    }
  }

  &Close {
    @include close-icon($social-bar-more-menu-text-color, 24px, 24px);
    @include stretch(absolute, auto, 30px, 22px, auto);

    z-index: $z-5;

    margin-right: 4px;

    @include media(tablet desktop large) {
      @include stretch(absolute, auto, 30px, 20px, auto);
    }
  }
}

.enter {
  transform: scale(0);

  opacity: 0.01;

  &Active {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    transform: scale(1);

    opacity: 1;
  }
}

.leave {
  transform: scale(1);

  opacity: 1;

  &Active {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: scale(0);

    opacity: 0.01;
  }
}
