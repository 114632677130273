@import 'assets/stylesheets/commons';

$section-dimmed-background-color: $base-gray-lighter-color;
$section-dimmed-text-color: $base-greyish-brown;

$section-white-background-color: $base-white-color;

$section-active-text-color: $base-white-color;
$section-active-background-color: $base-dark-purple-color;

$full-width: (
  column-width: false
);

.section {
  padding: 50px 0;

  &Dimmed {
    color: $base-greyish-brown;
    background: $section-dimmed-background-color;
  }

  &Primary {
    color: $section-active-text-color;
    background: $section-active-background-color;
  }

  &Small {
    padding: 20px 0;
  }

  &Container {
    @include brokenships-container($container-max-width);

    &Wide {
      @include with-layout($full-width) {
        @include container();
      }
    }
  }
}
