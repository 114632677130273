@import 'assets/stylesheets/commons';

$sponsors-section-placeholder-color: $base-pinkish-grey-color;

.sponsors {
	@include container($container-max-width);

	&Row {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		
		@include media(small mobile tablet) {
			flex-direction: column;
			gap: 2rem;
		  }
	}

	&Column {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@include media(small mobile tablet) {
			text-align: center;
		  }
		
		span {
			color: $european-color;
		}
	}

	&Disclaimer {
		font-weight: 300;
	}
}