@import 'assets/stylesheets/commons';

$pinned-news-background-color: $base-white-color;

$pinned-news-text-color: $base-greyish-brown;

$pinned-news-box-background-color: $primary-color;
$pinned-news-box-text-color: $base-white-color;
$pinned-news-box-subtitle-color: lighten($primary-color, 20%);
$pinned-news-box-description-color: $base-white-three;

.pinnedNews {
  @include container();

  &Header {
    display: flex;
    align-items: center;
    padding-top: 50px;

    @include media(desktop large) {
      padding-bottom: 28px;
    }
    @include media(small mobile) {
      padding-top: 10px;
      flex-direction: column;
      align-items: left;
    }
  }

  &HeaderTitle {
    font-size: 34px;
    font-weight: 300;
    margin: 0;

    color: $pinned-news-text-color;
    flex: 1;

    @include media(small mobile) {
      order: 2;
    }
  }

  &HeaderActions {
    flex: 0;
    margin-left: 25px;

    @include media(small mobile) {
      order: 1;
      margin-bottom: 57px;
      margin-left: 0;
    }
  }

  &Content {
    @include span(12 of 12);
  }

  &Loader {
    display: block;

    width: 100%;

    text-align: center;
  }

  &Item {
    position: relative;

    @include media(desktop large) {
      @include gallery(6);
    }
    @include media(small mobile tablet) {
      @include span(12);

      margin-top: 20px;
    }
    @include media(tablet, landscape) {
      @include gallery(6);
    }
    @include media(small mobile tablet) {
      overflow: hidden;
    }
  }

  &Img {
    position: relative;

    display: block;

    max-width: 520px;

    @include media(small mobile tablet) {
      width: 100%;
      max-width: 100%;
    }
    @include media(desktop large) {
      height: 390px;
    }
    @include media(desktop large) {
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 30px;

        content: '';
        transform: skewY(-2deg) translateY(100%);
        transform-origin: 0 50%;

        background: $pinned-news-background-color;
      }
    }

    &Loader {
      width: 100%;
      height: 100%;

      & &Img {
        width: auto;
        max-width: 100%;
      }
    }
  }

  &Box {
    position: absolute;

    max-width: 388px;

    background: $pinned-news-box-background-color;

    @include media(small mobile tablet) {
      position: relative;

      width: 100%;
      max-width: 100%;

      &::before {
        position: absolute;
        top: -30px;
        left: 0;

        width: 100%;
        height: 30px;

        content: '';
        transform: skewY(-2deg) translateY(100%);
        transform-origin: 0 50%;

        background: $pinned-news-box-background-color;
      }
    }

    &Wrapper {
      position: relative;
    }

    &Content {
      padding: {
        top: 20px;
        right: 30px;
        bottom: 20px;
        left: 30px;
      }
    }

    &Link {
      cursor: pointer;

      text-decoration: none;

      color: $pinned-news-box-text-color;

      &:hover {
        text-decoration: underline;
      }
    }

    &Title {
      font-size: 22px;
      font-weight: 600;

      margin: {
        top: 30px;
        bottom: 12px;
      }

      color: $pinned-news-box-text-color;
    }

    &Subtitle {
      font-size: 16px;
      line-height: 20px;

      margin-bottom: 7px;

      color: $pinned-news-box-subtitle-color;
    }

    &Description {
      font-size: 16px;
      line-height: 24px;

      color: $pinned-news-box-description-color;
    }

    &Footer {
      padding: {
        right: 30px;
        bottom: 17px;
        left: 30px;
      }
    }
  }

  &Item:nth-child(odd) &Img {
    float: none;

    padding-right: 0;

    @include media(desktop large) {
      float: left;

      margin-bottom: 150px;
    }
  }

  &Item:nth-child(even) &Img {
    float: none;

    padding-left: 0;

    @include media(desktop large) {
      float: right;
    }
  }

  &Item:nth-child(odd) &Box {
    @include media(desktop large) {
      right: 20px;
      bottom: 20px;
    }
  }

  &Item:nth-child(even) &Box {
    @include media(desktop large) {
      top: 246px;
      left: 30px;
    }
  }
}
