@import 'assets/stylesheets/commons';

$social-bar-more-overlay-color: $base-black-color;
$social-bar-more-menu-color: $secondary-color;
$social-bar-more-menu-text-color: $secondary-text-color;

.socialBar {
  @include container();

  &Toggle {
    float: right;

    width: 28px;
    height: 28px;
    margin: {
      right: 4px;
      bottom: 4px;
    }
    padding: {
      top: 6px;
      left: 5px;
    }

    cursor: pointer;
  }
}
