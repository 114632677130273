@import 'assets/stylesheets/commons';

$filter-item-font-size: $font-size-base;
$filter-item-mobile-font-size: $font-size-small;
$filter-item-normal-text-color: rgba($base-black-color, 0.5);
$filter-item-normal-border-color: rgba($base-black-color, 0.1);
$filter-item-hover-text-color: $secondary-text-color;
$filter-item-hover-background-color: $primary-color;

.filter {
  @include susy-clearfix;

  display: inline-block;

  margin: 0;
  padding: 0;

  list-style: none;

  @include media(small) {
    display: flex;

    width: 100%;
  }

  &Item {
    font-size: $filter-item-font-size;
    font-weight: 600;
    line-height: $filter-item-font-size;

    float: left;

    padding: 19px 30px;

    list-style: none;

    cursor: pointer;

    transition: {
      timing-function: ease-in-out;
      duration: 0.1s;
      property: background, color, border;
    }

    text-transform: uppercase;

    color: $filter-item-normal-text-color;
    border: {
      top: 1px solid $filter-item-normal-border-color;
      right: none;
      bottom: 1px solid $filter-item-normal-border-color;
      left: 1px solid $filter-item-normal-border-color;
    }

    @include media(small) {
      font-size: $filter-item-mobile-font-size;

      padding: 8px 4px;

      flex: 1;
    }

    @include media(mobile) {
      font-size: $filter-item-mobile-font-size;

      padding: 8px 10px;
    }

    @include media(tablet) {
      padding: 12px 15px;
    }

    @include media(desktop large) {
      &:hover {
        color: $filter-item-hover-text-color;
        border-color: $filter-item-hover-background-color;
        background: $filter-item-hover-background-color;
      }
    }

    &:last-child {
      border-right: 1px solid $filter-item-normal-border-color;
    }

    &Active {
      color: $filter-item-hover-text-color;
      border-color: $filter-item-hover-background-color;
      background: $filter-item-hover-background-color;
    }
  }
}
