@import 'assets/stylesheets/commons';

$loader-fill-color: $base-white-color;

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

@keyframes heartbeat-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    transform: scale(5);
    opacity: 0;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &Svg {
    width: 80px;
    height: auto;
    fill: $loader-fill-color;

    &Loading {
      animation-name: heartbeat;
      animation-duration: 1300ms;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }

    &Done {
      animation-name: heartbeat-out;
      animation-duration: 1000ms;
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
      animation-fill-mode: forwards;
    }
  }
}
