@import 'assets/stylesheets/commons';

$panel-separator-text-color: $base-brownish-grey;

.panelSeparator {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;

  margin: 0 0 36px 0;

  text-align: center;

  color: $panel-separator-text-color;
}
