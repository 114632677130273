@import 'assets/stylesheets/commons';

$edit-bar-link-normal-color: $base-black-color;
$edit-bar-link-hover-color: $primary-color;

.editBar {
  &Link {
    font-size: 18px;

    display: inline-block;

    cursor: pointer;
    transition: color 0.2s ease-in-out;

    color: $edit-bar-link-normal-color;

    & + & {
      margin-left: 16px;
    }

    &Icon {
      display: inline-block;

      width: 28px;
      height: 28px;
      margin-right: 4px;

      vertical-align: middle;

      path {
        transition: fill 0.2s ease-in-out;

        fill: $edit-bar-link-normal-color;
      }
    }

    &:hover {
      color: $edit-bar-link-hover-color;

      .editBarLinkIcon path {
        fill: $edit-bar-link-hover-color;
      }
    }
  }
}
