@import 'assets/stylesheets/commons';

$story-gradient-bottom: $base-black-color;
$story-gradient-top: transparent;

$story-background-color: $base-white-color;
$story-overlay-text-color: $base-white-color;
$story-primary-shadow-color: rgba($base-black-color, 0.15);
$story-image-background-color: $primary-color;
$story-image-default-background-color: $base-white-color;
$story-font-color: $base-black-color;
$story-image-locked-background-color: $base-white-color;
$story-locked-text-color: $base-warm-gray-color;
$story-in-review-text-color: $base-white-color;
$story-overlay-background-color: $base-white-color;
$story-overlay-invert-background-color: $base-black-color;

.post {
  position: relative;

  display: block;

  width: span(3);

  text-decoration: none;

  @include media(tablet) {
    width: span(6);
  }

  @include media(small mobile) {
    width: span(12);
  }

  &Link {
    @include post-hover-animation($story-primary-shadow-color, 'before');
  }

  &Horizontal {
    width: span(6);

    @include media(small mobile) {
      width: span(12);
    }
  }

  &::before {
    @include stretch($top: 15px);

    display: block;

    content: '';
    transform: skewY(-2deg);

    background: $story-background-color;
  }

  &Wrapper {
    position: relative;

    &Locked {
      padding-bottom: 10px;

      @include media(small mobile) {
        padding-bottom: 15px;
      }
    }
  }

  &Media {
    position: relative;

    display: flex;

    overflow: hidden;

    width: 100%;
    min-height: 70px;

    background: $story-image-background-color;

    justify-content: center;
    align-items: center;

    &Default {
      background: $story-image-default-background-color;
    }

    &Locked {
      min-height: 300px;

      background-color: $story-image-locked-background-color;
    }

    &Story {
      &::after {
        position: absolute;

        right: 0;
        bottom: 0;
        left: 0;

        height: 80px;

        content: '';

        opacity: 0.4;

        background: linear-gradient(to top, $story-gradient-bottom, $story-gradient-top);
      }
    }

    &Author {
      position: absolute;
      z-index: $z-1;

      right: 0;
      bottom: 0;
      left: 0;

      padding: 16px 24px;

      &Transparent {
        opacity: 0.4;
      }
    }

    &Landscape {
      float: left;

      width: 40%;
    }
  }

  &Image {
    width: 100%;

    vertical-align: top;
  }

  &Overlay,
  &OverlayInvert {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &Content {
      position: absolute;
      top: 50%;
      left: 0;

      width: 100%;

      transform: translateY(-50%);
    }
  }

  &Overlay {
    color: $story-overlay-text-color;
  }

  &OverlayInvert {
    display: flex;
    overflow: hidden;

    background: rgba($story-overlay-invert-background-color, 0.5);

    align-items: center;
    justify-content: center;
  }

  &Locked {
    padding-top: 15px;
    padding-bottom: 15px;

    &Icon {
      position: absolute;

      top: 50%;
      left: 50%;

      display: block;

      width: 95px;
      height: 149px;

      transform: translate(-50%, -60%);

      background-image: url('/assets/icons/ic-locked-large.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain; // stylelint-disable-line
    }

    &Text {
      font-size: 28px;
      font-weight: 600;
      line-height: 29px;

      margin: 0;

      text-align: center;

      color: $story-locked-text-color;
    }
  }

  &InReview {
    padding: 40px 5% 70px 5%;

    &Text {
      font-size: 28px;
      font-weight: 500;
      line-height: 33px;

      margin: 0;

      text-align: center;

      color: $story-in-review-text-color;
    }
  }

  &Content {
    padding: 25px 30px 0 30px;

    background: $story-background-color;

    &Landscape {
      float: right;

      width: 60%;
    }
  }

  &Type {
    font-size: 18px;
    line-height: 1.4;
		text-transform: uppercase;
    padding-bottom: 2px;

    opacity: 0.7;
    color: $story-font-color;
  }


  &Title {
    font-size: 19px;
    font-weight: 600;
    line-height: 1.4;

    margin: 0;
    padding-bottom: 3px;

    color: $story-font-color;
  }

  &Info {
    font-size: 16px;
    line-height: 1.2;

    padding-bottom: 12px;

    opacity: 0.4;
    color: $story-font-color;
  }

  &Desc {
    font-size: 16px;
    line-height: 1.5;

    opacity: 0.6;
    color: $story-font-color;
  }

  &Footer {
    min-height: 62px;
    padding: 15px 30px;

    &Landscape {
      float: right;

      width: 60%;
    }
  }
}

.appear {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translateY(50px);

  opacity: 0.01;

  &Active {
    transform: translateY(0);

    opacity: 1;
  }
}
