@import '../assets/stylesheets/commons';

.home {
  position: relative;

  &JumbotronContent {
    max-width: none;
    padding: 0;
		min-height: 400px;
  }
}

.action {
  text-align: center;
}
