@import 'assets/stylesheets/commons';

$subscribe-box-placeholder-color: $base-pinkish-grey-color;
$subscribe-box-subscribed-message-color: $secondary-color;

.wrapper {
  @include container($container-max-width);

  @include media(mobile tablet) {
    @include container(90%);
  }
}

.container {
  @include span(8);
  @include push(2);
}

.title {
  @include span(3);

  font-size: 18px;
  font-weight: 600;

  text-transform: uppercase;
}

.email {
  @include span(5);
}

.input {
  padding: 13px 0;

  background-color: transparent;

  @include placeholder {
    opacity: 1;
    color: $subscribe-box-placeholder-color;
  }
}

.submitWrapper {
  @include span(4 last);
}

.submit {
  height: 48px;

  white-space: nowrap;
}

.title,
.email,
.button {
  line-height: 48px;
}

@include media(small mobile tablet) {
  .title,
  .email,
  .submitWrapper {
    @include span(12);
  }

  .submitWrapper {
    margin-top: 12px;
  }
}

.subscribed {
  font-size: 18px;
  font-weight: 600;

  text-align: center;

  color: $subscribe-box-subscribed-message-color;
}
