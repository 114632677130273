@import 'assets/stylesheets/commons';

$loader-birder-color: $base-white-color;

.wrapper {
  display: inline-block;
}

.image {
  width: 100%;
  max-height: 100%;

  vertical-align: middle;
}

.preloader {
  position: relative;

  width: 100%;

  text-align: center;

  &Image {
    width: 100%;

    vertical-align: middle;
  }

  &Spinner {
    position: absolute;
    z-index: $z-loader;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    &Inner {
      @include spinner ($loader-birder-color, 3px, 45px);
    }
  }
}
