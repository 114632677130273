@import 'assets/stylesheets/commons';

$cluster-text-color: $base-white-color;
$cluster-shadow-color: rgba($base-black-color, 0.32);

$translate-wrapper: translate(-50%, -50%);
$appear-transition: transform 0.2s 1.5s ease-in-out;

.cluster {
  position: absolute;
  z-index: $z-1;
  top: 0;
  left: 0;

  &Positioner {
    position: relative;

    width: 0;
    height: 0;
  }

  &Wrapper {
    position: absolute;

    display: table;

    transform: translate(-50%, -50%);

    @at-root :global(.markerAppear)#{&} {
      transform-origin: 0% 0%;
      transform: scale(0) $translate-wrapper;
    }

    @at-root :global(.markerAppearActive)#{&} {
      transform: scale(1) $translate-wrapper;
      transition: $appear-transition;
    }
  }

  &Content {
    position: relative;

    display: table-row;

    cursor: pointer;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    transform: scale(1);

    color: $cluster-text-color;

    &:hover {
      transform: scale(1.2);
    }
  }

  &Disabled &Content {
    opacity: 0.2;
  }

  &Icon {
    display: table-cell;
  }

  &Svg {
    width: 20px;
    height: 20px;

    vertical-align: top;

    fill: $cluster-text-color;
  }

  &Text {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;

    display: table-cell;

    margin-top: -2px;
    padding-left: 8px;

    text-shadow: 0 1px 3px $cluster-shadow-color;
  }
}
