@import 'assets/stylesheets/commons';

$map-museum-background-normal-color: $base-white-three;
$map-museum-text-color: $primary-color;
$map-museum-shadow-color: rgba($base-black-color, 0.32);
$map-museum-arrow-shadow-color: $base-black-color;

$translate-current-wrapper: translate(-50%, -100%);
$translate-past-wrapper: translate(-50%, -50%);

.exhibition {
  position: absolute;
  z-index: $z-2;
  top: 0;
  left: 0;

  cursor: default;

  transition: opacity 200ms ease-in-out;

  &:hover {
    z-index: $z-5;
  }

  &Positioner {
    position: relative;

    width: 0;
    height: 0;
  }

  &Wrapper {
    position: absolute;
  }

  &Content {
    position: relative;

    display: table;
  }

  &Text {
    font-size: 0;
    font-weight: 600;
    line-height: 0px; // stylelint-disable-line

    display: table-cell;

    transition: transform 200ms ease-in-out, font-size 200ms ease-in-out, line-height 200ms ease-in-out;
    transform: scale(1);
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;

    color: $map-museum-text-color;
    background: $map-museum-background-normal-color;
    box-shadow: 0 1px 3px $map-museum-shadow-color;
  }

  // CURRENT

  &Current {
    z-index: $z-2;
  }

  &Current &Wrapper {
    transform: $translate-current-wrapper;
  }

  &Current &Content {
    @include tooltip-arrow(
      $color: $map-museum-background-normal-color,
      $position: (bottom center),
      $width: 8px,
      $height: 5px,
      $shadow: (0 1px 3px $map-museum-shadow-color)
    );

    margin-bottom: 4px;
  }

  &Current &Text {
    padding: 7px 8px 9px;

    transform-origin: 50% 100%;
  }

  // PAST

  &Past {
    z-index: $z-1;
  }

  &Past &Wrapper {
    transform: $translate-past-wrapper;
  }

  &Past &Content { // stylelint-disable-line block-no-empty
  }

  &Past &Text {
    padding: 2px 3px 4px;

    transform-origin: 50% 50%;
  }

  @at-root
  a#{&} &Text:hover { // stylelint-disable-line
    cursor: pointer;
  }

  @at-root
  a#{&} &Text:hover { // stylelint-disable-line
    font-size: 16px;
    line-height: 18px;

    transform: scale(1.25);
  }

  &Disabled {
    opacity: 0.2;
  }
}
