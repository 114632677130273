@import 'assets/stylesheets/commons';
@import 'assets/stylesheets/components/panelSeparator';

.posts {
  display: block;

  &Content {
    display: block;
  }

  &Action {
    padding-bottom: 50px;

    text-align: center;
  }
}
