@import 'assets/stylesheets/commons';

$input-text-color: $base-black-color;
$input-placeholder-text-color: $base-pinkish-grey-color;
$input-disabled-text-color: $base-brownish-grey;
$input-background-color: transparent;
$input-normal-border-color: $base-pinkish-grey-color;
$input-focus-border-color: $base-purple-color;
$input-error-border-color: $base-red-color;

$input-warning-border-color: $base-orange-color;

$input-show-hide-active-color: $base-brownish-grey;

.input {
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;

  display: block;

  width: 100%;
  padding: 10px 0 15px 0;

  color: $input-text-color;
  border: {
    top: none;
    right: none;
    bottom: 1px solid $input-normal-border-color;
    left: none;
  }
  border-radius: 0;

  background-color: $input-background-color;

  appearance: none; // stylelint-disable-line

  @include placeholder {
    opacity: 1;
    color: $input-placeholder-text-color;
  }

  &:focus {
    outline: none;
  }

  //scss-lint:disable MergeableSelector
  &:focus + &Underline {
    width: 100%;
  }
  //scss-lint:enable MergeableSelector

  //scss-lint:disable ImportantRule
  &::-ms-reveal,
  &::-ms-clear {
    display: none !important; // stylelint-disable-line
  }
  //scss-lint:enable ImportantRule

  &Multiline {
    height: 109px;
  }

  &[disabled] {
    text-decoration: line-through;

    color: $input-disabled-text-color;

    @include placeholder {
      text-decoration: line-through;
    }
  }

  &Wrapper {
    position: relative;

    display: block;
  }

  &Underline {
    position: absolute;
    bottom: 0;

    display: block;

    width: 0;
    height: 2px;

    transition: width 0.2s ease-in-out;

    background: $input-focus-border-color;

    &Error {
      width: 100%;

      background: $input-error-border-color;
    }

    &Warning {
      width: 100%;

      background: $input-warning-border-color;
    }
  }

  &ShowHide {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    width: 25px;

    cursor: pointer;

    border-radius: 4px;
    background: url('../../../assets/icons/wink.svg') no-repeat center right;

    &Safari {
      right: 32px;
    }
  }
}
