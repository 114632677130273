@import 'assets/stylesheets/commons';

$heading-text-color: $base-greyish-brown;

.heading {
  @include susy-clearfix;

  margin-bottom: 26px;

  @include media(small mobile) {
    margin-bottom: 15px;
  }

  &Text {
    font-size: $font-size-h3;
    font-weight: 300;
    line-height: 54px;

    display: inline-block;
    float: left;

    margin: 0;

    color: $heading-text-color;

    @include media(small mobile) {
      display: block;
      float: none;

      width: 100%;

      margin-bottom: 15px;

      text-align: center;
    }
  }

  &Accessories {
    float: right;

    @include media(small mobile) {
      float: none;

      text-align: center;
    }
  }
}
