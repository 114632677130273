@import 'assets/stylesheets/commons';

.jumbotronOverlay {
  @include container($container-max-width);

  position: relative;

  user-select: none;

  @include media(small mobile) {
    margin-top: -50px;
    z-index: $z-4;
  }

  &Container {
    @include brokenships-container($container-max-width);

    position: absolute;
    z-index: $z-2;
    bottom: 0;

    width: 100%;

    padding-bottom: 20px;

    @include media(small mobile) {
      position: relative;
    }
  }

  &InfoLeft {
    width: span(8);

    @include media(small mobile) {
      width: auto;
      position: absolute;

      left: 25px;
      bottom: 100%;
      right: 0;
    }
  }

  &InfoRight {
    position: absolute;
    right: $container-padding;
    bottom: -40px;

    width: span(4);

    @include media(tablet) {
      right: 0;
    }
    @include media(small mobile) {
      position: relative;
      right: 0;
      bottom: 0;

      float: right;

      width: span(12);
      margin-bottom: -40px;
    }
  }
}
