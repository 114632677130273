@import 'assets/stylesheets/commons';

.SocialLinks {
  display: flex;
  align-items: center;

  @include media(small mobile) {
    flex-direction: column;
    align-items: left;
  }

  &Label {
    font-size: 15px;
    line-height: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: $base-brownish-grey;
    white-space: nowrap;

    @include media(small mobile) {
      margin-bottom: 16px;
    }
  }

  &Content {
    margin: 0 0 0 32px;
    padding: 0;
		display: flex;

    @include media(small mobile) {
      margin-left: 0;
    }
  }

  &Item {
    display: inline;
    white-space: nowrap;
    padding: 0 12px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &Link {
    display: inline-block;
  }
}
