$vertical-position-options: (top bottom);
$horizontal-position-options: (left center right);
$position-default: (bottom left);

@function normalizePosition($position) {
  $first-position: nth($position, 1);
  $second-position: nth($position, 2);
  $vertical-position: nth($position-default, 1);
  $horizontal-position: nth($position-default, 2);

  @if (index($vertical-position-options, $first-position)) {
    $vertical-position: $first-position;
  }

  @if (index($horizontal-position-options, $first-position)) {
    $horizontal-position: $first-position;
  }

  @if (index($vertical-position-options, $second-position)) {
    $vertical-position: $second-position;
  }

  @if (index($horizontal-position-options, $second-position)) {
    $horizontal-position: $second-position;
  }

  @return ($vertical-position $horizontal-position);
}

@mixin arrow($color, $position, $width, $height, $offsetX: 0, $offsetY: 0) {
  @if (type-of($position) == list) && (length($position) == 2) {
    $position: normalizePosition($position);
    $onePixelGapFix: 1;

    $verticalPosition: nth($position, 1);
    $horizontalPosition: nth($position, 2);

    @if ($verticalPosition == bottom) {
      bottom: -$height - $offsetY + $onePixelGapFix;

      border-top: $height solid $color;
      border-bottom: 0 solid transparent;
    }

    @if ($verticalPosition == top) {
      top: -$height + $offsetY + $onePixelGapFix;

      border-too: 0 solid transparent;
      border-bottom: $height solid $color;
    }

    @if ($horizontalPosition == right) {
      right: 0 + $offsetX;
      border-right: 0 solid transparent;
      border-left: $width solid transparent;
    }

    @if ($horizontalPosition == left) {
      left: 0 + $offsetX;
      border-right: $width solid transparent;
      border-left: 0 solid transparent;
    }

    @if ($horizontalPosition == center) {
      left: 50%;
      transform: translateX(-50%);
      border-right: $width / 2 solid transparent;
      border-left: $width / 2 solid transparent;
    }
  }

  @else {
    @error 'Position should be a list: ($verticalPosition $horizontalPosition)';
  }
}

@mixin tooltip-arrow($color, $position: $position-default, $width: 10px, $height: 10px, $shadow: null) {
  @if $shadow {
    @if (type-of($shadow) == list) && (length($shadow) == 4) {
      $x: nth($shadow, 1);
      $y: nth($shadow, 2);
      $blur: nth($shadow, 3);
      $shadowColor: nth($shadow, 4);

      &::before {
        position: absolute;

        display: block;

        content: '';

        filter: blur($blur); // stylelint-disable-line no-unsupported-browser-features

        @include arrow($shadowColor, $position, $width, $height, $x, $y);
      }
    }

    @else {
      @error 'Shadow should be a list: (x y blur color)';
    }
  }

  &::after {
    position: absolute;

    display: block;

    content: '';

    @include arrow($color, $position, $width, $height);
  }
}