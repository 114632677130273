@import 'assets/stylesheets/commons';

$panel-item-description-text-color: $base-warm-gray-color;
$panel-item-link-color: $primary-color;
$panel-item-link-hover-color: darken($panel-item-link-color, 25%);

.panel {
  &Content {
    @include container($container-max-width);

    margin-bottom: 100px;

    @include media(small mobile) {
      margin-bottom: 0;
    }
  }

  &Col {
    @include span(4);

    @include media(small mobile) {
      @include span(8);
      @include push(2);

      margin-bottom: 35px;
    }

    @include media(small) {
      @include span(12);
    }
  }

  &ColLast {
    @include span(4 last);

    @include media(small mobile) {
      @include span(8);
      @include push(2);
    }

    @include media(small) {
      @include span(12);
    }
  }

  &Item {
    display: table;

    width: 100%;

    padding-right: 30px;
  

    &Icon {
      display: table-cell;

      width: 64px;
      padding-right: 20px;

      opacity: 0.3;
    }

    &Content {
      display: table-cell;

      vertical-align: top;
    }

    &Descritpion {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.4;

      margin-top: 0;
      margin-bottom: 8px;

      color: $panel-item-description-text-color;
    }
  }
}

.link {
  font-size: 18px;
  line-height: 1.4;

  position: relative;

  display: inline;

  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.5px;
  text-transform: uppercase;

  color: $panel-item-link-color;

  border-bottom: 2px solid $panel-item-link-color;

  &:hover {
    color: $panel-item-link-hover-color;

    border-bottom-color: $panel-item-link-hover-color;
  }
}
