@import 'assets/stylesheets/commons';

.postsContainer {
  @include container();

  position: relative;

  padding: {
    bottom: 50px;
  }

  transition: opacity 0.5s ease-in-out;

  &GutterSizer {
    width: gutter(after);
  }

  &GridSizer {
    width: span(3);

    @include media(tablet) {
      width: span(6);
    }

    @include media(small mobile) {
      width: span(12);
    }
  }

  &Item {
    margin-bottom: 40px;

    // HACK Appears to fix spacing issues on re-render due to image list changing
    @include media(desktop large) {
      margin-bottom: 52px;
    }
  }

  &Empty {
    @include span(12 of 12);

    &Item {
      @include span(4 of 12);
      @include push(4);

      margin-bottom: 30px;
    }

    &Image {
      width: 100%;
    }

    &Description {
      margin-top: 0;

      text-align: center;
    }
  }
}
