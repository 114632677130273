@import 'assets/stylesheets/commons';

$map-museum-background-normal-color: $base-white-three;
$map-museum-text-color: $primary-color;
$map-museum-shadow-color: rgba($base-black-color, 0.32);
$map-museum-arrow-shadow-color: $base-black-color;

$translate-top-left-content: translate(-100%, -100%);
$translate-top-right-content: translate(0%, -100%);

.museum {
  position: absolute;
  z-index: $z-3;
  top: 0;
  left: 0;

  cursor: default;

  transition: opacity 200ms ease-in-out;

  &:hover {
    z-index: $z-5;
  }

  &Positioner {
    position: relative;

    width: 0;
    height: 0;
  }

  &Wrapper {
    position: absolute;
  }

  &Content {
    position: relative;

    display: block;
  }

  &Text {
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;

    display: block;

    padding: 8px 10px 10px 10px;

    transition: transform 200ms ease-in-out;
    transform: scale(1);
    text-align: center;
    white-space: nowrap;

    color: $map-museum-text-color;
    background: $map-museum-background-normal-color;
    box-shadow: 0 1px 3px $map-museum-shadow-color;
  }

  @at-root
  a#{&} &Text:hover { // stylelint-disable-line
    cursor: pointer;
  }

  @at-root
  a#{&} &Text:hover { // stylelint-disable-line
    transform: scale(1.25);
  }

  &Disabled {
    opacity: 0.2;
  }

  &TopLeft &Wrapper {
    transform: $translate-top-left-content;
  }

  &TopLeft &Content {
    @include tooltip-arrow(
      $color: $map-museum-background-normal-color,
      $position: (bottom right),
      $width: 15px,
      $height: 11px,
      $shadow: (0 1px 3px $map-museum-shadow-color)
    );

    margin-bottom: 10px;
  }

  &TopLeft &Text {
    transform-origin: 100% 100%;
  }

  &TopRight &Wrapper {
    transform: $translate-top-right-content;
  }

  &TopRight &Content {
    @include tooltip-arrow(
      $color: $map-museum-background-normal-color,
      $position: (bottom left),
      $width: 15px,
      $height: 11px,
      $shadow: (0 1px 3px $map-museum-shadow-color)
    );

    margin-bottom: 10px;
  }

  &TopRight &Text {
    transform-origin: 0% 100%;
  }
}
