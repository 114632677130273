@import 'assets/stylesheets/commons';

$world-map-poi-background-color: $primary-color;
$world-map-poi-text-color: $base-white-three;

$world-map-svg-shadow-color: #644589;
$world-map-path-color: $base-purple-color;

.semibold {
  font-weight: normal;
}

.map {
  position: relative;

  overflow: hidden;

  height: 780px;
  padding-top: 60px;

  user-select: none;

  @include media(small mobile) {
    height: 640px;
  }

  &Wrapper {
    position: relative;
    transition: opacity 0.5s 0.2s ease-in-out;
  }

  &Path {
    fill: $world-map-path-color;
  }
}
