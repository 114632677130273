@import 'assets/stylesheets/commons';

$author-badge-text-color: $base-black-color;
$author-badge-text-invert-color: $base-white-color;
$author-badge-big-text-color: $primary-color;
$author-badge-background-color: $base-white-color;
$author-badge-background-invert-color: $base-darker-purple;
$author-badge-diameter-medium: 37px;
$author-badge-diameter-big: 70px;
$author-badge-background-locked-color: $base-gray-lighter-color;
$author-label-text-color: $secondary-text-color;
$author-label-text-locked-color: rgba($base-black-color, 0.6);
$author-label-text-invert-color: $base-black-color;

.author {
  position: relative;

  padding: {
    left: $author-badge-diameter-medium;
  }

  &Big {
    padding: {
      left: $author-badge-diameter-big;
    }
  }

  &Badge {
    font-size: 16px;
    font-weight: 600;
    line-height: 17px;

    position: absolute;
    top: 0;
    left: 0;

    width: $author-badge-diameter-medium;
    height: $author-badge-diameter-medium;
    padding: {
      top: 10px;
      bottom: 10px;
    }

    text-align: center;

    color: $author-badge-text-color;
    border-radius: 50%;
    background: $author-badge-background-color;

    &Big {
      width: $author-badge-diameter-big;
      height: $author-badge-diameter-big;

      padding: {
        top: 26px;
        bottom: 26px;
      }

      color: $author-badge-big-text-color;
    }

    &Invert {
      @include font-smoothing;

      color: $author-badge-text-invert-color;
      background-color: $author-badge-background-invert-color;
    }

    &Locked {
      background-color: $author-badge-background-locked-color;
    }
  }

  &Label {
    font-size: 16px;
    font-weight: 600;

    padding: {
      top: 10px;
      bottom: 10px;
      left: 10px;
    }

    color: $author-label-text-color;

    &Locked {
      color: $author-label-text-locked-color;
    }

    &Invert {
      color: $author-label-text-invert-color;
    }

    &Big {
      font-size: 56px;
      font-weight: 200;
      line-height: 54px;
    }
  }
}
