@import 'assets/stylesheets/commons';

$like-button-text-color: $base-black-color;
$like-button-text-inverted-color: $base-white-color;

.likeButton {
  font-size: 18px;
  line-height: 1.4;

  float: left;

  margin: {
    right: 16px;
  }

  cursor: pointer;

  color: $like-button-text-color;

  &:hover {
    opacity: 0.6;
  }

  &Inverted {
    color: $like-button-text-inverted-color;
  }

  &Icon {
    display: inline-block;

    width: 22px;
    height: 18px;
    margin-right: 4px;

    vertical-align: middle;
  }
}
