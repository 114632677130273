@import 'assets/stylesheets/commons';

$map-stats-item-content-color: $base-white-color;
$map-stats-item-title-color: $base-white-color;

.mapStatsItem {
  display: inline-block;

  padding-right: 50px;
  padding-bottom: 24px;

  transition: opacity 200ms linear;

  text-align: left;

  cursor: pointer;

  @include media(small mobile) {
    width: 50%;
  }

  &Disabled {
    opacity: 0.2;
  }

  &Title {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;

    display: table-cell;

    letter-spacing: 1px;
    text-transform: uppercase;

    padding-bottom: 9px;

    color: $map-stats-item-title-color;
  }

  &Body {
    display: table;
    table-layout: fixed;

    height: 36px;
  }

  &Row {
    display: table-row;
  }

  &Icon {
    display: table-cell;

    width: 24px;

    vertical-align: middle;
  }

  &Value {
    display: table-cell;

    vertical-align: middle;

    padding-left: 20px;
  }

  &Animator {
		font-family: 'National';
    font-size: 40px;
    font-weight: normal;
    line-height: 40px;

    margin-top: -16px;

    color: $map-stats-item-content-color;
  }
}
