@import 'assets/stylesheets/commons';

$default-color: $base-black-color;
$default-hover-color: $base-purple-color;

$inverse-color: $base-white-color;

.socialIcon {

  svg {
    width: 18px;
    height: 18px;
  }

  &Outline {
    fill: $default-color;
  }

  &:hover &Outline {
    fill: $default-hover-color;
  }

  &OutlineInverse {
    fill: $inverse-color;
  }

  &:hover &OutlineInverse {
    opacity: 0.7;

    fill: $inverse-color;
  }
}
